.vimeoPlayer {
    width: 100%;
    height: 60vh;
    display:flex;
    justify-content: center;
}

.vimeoPlayer div {
    width: 100%;
    padding: 0% !important;
}