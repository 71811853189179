.intro-vimeoPlayer {
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    background: white;
}

.intro-vimeoPlayer div {
    width: 100%;
    padding: 0% !important;
}